import { OrderDetailItem, OrderItemListItem, OrderStatus } from '@slovgen-ui/api'

export function canShowToCustomer(order: OrderDetailItem, item: OrderItemListItem): boolean {
    if (!(order.status === OrderStatus.COMPLETED || order.status === OrderStatus.PAID)) {
        // console.log('Nema pristup - STAV')
        return false
    }
    // if (item.analysisDisabled) {
    //     return false
    // }
    if (!item.protocolNo && new Date(order.createdOn).getFullYear() > 2019) {
        // console.log('Nema pristup - protocolno')
        return false
    }
    if (!item.protocolApproved && new Date(order.createdOn).getFullYear() > 2019) {
        // console.log('Nema pristup - prootocolApprove')
        return false
    }
    return true
}
