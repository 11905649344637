import { Component, Input } from '@angular/core'
import { VoucherDetailItem } from '@slovgen-ui/api'

@Component({
    selector: 'oc-voucher-badge',
    template: `
        <div
            class="voucher mr-2 bg-yellow-400 flex cursor-pointer py-2"
            [ngClass]="{ 'bg-red-400': voucher.balance === 0 }"
            style="
        -webkit-mask-image: radial-gradient(circle at 50px 10px, transparent 10px, red 10.5px),
            radial-gradient(closest-side circle at 50%, red 99%, transparent 100%);
        -webkit-mask-size: 100%, 4px 12px;
        -webkit-mask-repeat: repeat, repeat-y;
        -webkit-mask-position: 0 -10px, 48px;
        -webkit-mask-composite: source-out;
        mask-composite: subtract;
    "
        >
            <div style="writing-mode: vertical-rl; width: 50px" class="text-center pr-3">
                <span class="vertical-align-bottom">{{ voucher.id }}</span>
            </div>
            <div class="flex flex-column pl-4 pr-2 h-full justify-content-around">
                <div class="text-base " style="min-width: 150px;">
                    <span class="text-xs pr-1">{{ 'voucher.balance' | translate }}</span>
                    <strong class="font-xl">{{ voucher.balance }}</strong> /
                    <span class="text-sm">{{ voucher.total }}</span>
                </div>
                <div>{{ voucher.type }}</div>
                <div>{{ voucher.issueDate | date : 'short' }}</div>
            </div>
        </div>
    `,
})
export class VoucherBadgeComponent {
    @Input({ required: true }) voucher!: VoucherDetailItem
}
