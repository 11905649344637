import { AbstractControl, Validators } from '@angular/forms'
import { AnimalClass } from '@slovgen-ui/shared'

export function RequiredForDogsValidator(formControl: AbstractControl) {
    if (!formControl.parent) {
        return null
    }

    if (formControl.parent.get('rootCategoryId').value === AnimalClass.DOG) {
        return Validators.required(formControl)
    }
    return null
}
