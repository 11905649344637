import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { ControlContainer, UntypedFormControl, Validators } from '@angular/forms'
import { FormArray, FormBuilder } from '@ngneat/reactive-forms'
import { FbGroupConfig } from '@ngneat/reactive-forms/lib/formBuilder'
import { AnalysisListItem } from '@slovgen-ui/api'
import { AnimalClass, CacheService, SelectItem } from '@slovgen-ui/shared'
import { ItemFormModel } from '../models/sample-form.model'

@Component({
    selector: 'oc-items-editor',
    templateUrl: './order-items-editor.component.html',
    styles: [],
})
export class OrderItemsEditorComponent implements OnInit, OnChanges {
    @Input()
    adminMode = false

    @Input()
    rootCategoryId!: number

    @Input()
    categoryId!: number

    @Output()
    remove: EventEmitter<number> = new EventEmitter()

    usedAnalysesIds: number[] = []

    selectedAnalyses: AnalysisListItem[] = []

    orderItemsForm!: FormArray<ItemFormModel>
    selectedAnalysisId = new UntypedFormControl(null)
    analysisSelectItems!: SelectItem<number>[]
    isBird = false
    analysisMap: Map<number, AnalysisListItem>
    showCertInfo: { [key: number]: boolean } = {}

    constructor(private fb: FormBuilder, public cache: CacheService, private controlContainer: ControlContainer) {
        this.analysisMap = this.cache.analysisCache
    }

    ngOnInit() {
        this.orderItemsForm = this.controlContainer.control as FormArray<ItemFormModel>
        this.updateAnalyses()
    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log(`On changes ${changes.rootCategoryId} ${this.rootCategoryId}`)
        if (!this.orderItemsForm) {
            this.orderItemsForm = this.controlContainer.control as FormArray<ItemFormModel>
        }
        if (changes.rootCategoryId || changes.categoryId) {
            this.isBird = this.rootCategoryId === AnimalClass.BIRD
            this.updateAnalyses()
        }
    }
    updateAnalyses() {
        this.usedAnalysesIds = []
        this.analysisSelectItems = this.adminMode
            ? this.cache.analysisSelectItemsInclDisabled
            : this.cache.analysisSelectItems
        // only those, that have no restructions, or are restricted for our breed
        this.analysisSelectItems = this.analysisSelectItems.filter((ais) => {
            if (this.cache.analysisCache.get(ais.id)?.breedRestriction) {
                const isEnabled =
                    this.cache.analysisCache.get(ais.id)?.breedRestriction.includes(this.categoryId) ||
                    this.cache.analysisCache.get(ais.id)?.breedRestriction.includes(this.rootCategoryId)
                // if (!isEnabled) {
                //     console.log(
                //         `Remove ${ais.label} ${this.cache.analysisCache.get(ais.id).breedRestriction} does not have ${
                //             this.categoryId
                //         } or ${this.rootCategoryId}`
                //     )
                // }
                return isEnabled
            } else {
                return true
            }
        })
        this.selectedAnalyses = []
        this.orderItemsForm.value.forEach((item) => {
            this.addUsedAnalysis(item.analysisId)
        })
        //vybrane analyzy odstatnit z ponuky
        this.updateSelectItems()
    }

    addUsedAnalysis(analysisId: number) {
        this.selectedAnalyses.push(this.cache.analysisCache.get(analysisId))
        this.usedAnalysesIds.push(analysisId)
        // this.analysisSelectItems.find(a => a.id === analysisId).disabled = true;

        const analysis = this.cache.analysisCache.get(analysisId)

        if (analysis && analysis.groupAnalysis) {
            this.showCertInfo[analysis.id] = this.includesAnalysisWithCert(analysis)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.usedAnalysesIds.push(...analysis.groupItems!)
        }
    }

    includesAnalysisWithCert(analysis: AnalysisListItem): boolean {
        const result = analysis.groupItems?.map((i) => this.analysisMap.get(i)).some((a) => a?.certificateAvailable)
        // console.log(`evald ${result}`)
        return result || false
    }

    addAnalysis(analysisId: number) {
        this.addUsedAnalysis(analysisId)
        //vybrane analyzy odstatnit z ponuky
        this.updateSelectItems()
        const adminItems: FbGroupConfig = this.adminMode
            ? {
                  analysisDate: [new Date()],
                  analysisGroupApplied: [null],
                  existingOrderItemId: [null],
                  note: [null],
                  resultId: [null],
              }
            : {}
        this.orderItemsForm.push(
            this.fb.group<ItemFormModel>({
                ...adminItems,
                analysisId: [analysisId, Validators.required],
                includingDecorativeCert: [false],
                priorityDelivery: [false],
            })
        )
        this.selectedAnalysisId.setValue(null)
    }

    private updateSelectItems() {
        this.analysisSelectItems = this.analysisSelectItems.map((i) => ({
            ...i,
            disabled: this.usedAnalysesIds.includes(i.id),
        }))
    }

    removeAnalysis(index: number) {
        this.orderItemsForm.removeAt(index)
        const deletedAnalysis = this.selectedAnalyses.splice(index, 1)[0]
        const idsToDelete = [deletedAnalysis.id, ...(deletedAnalysis.groupItems || [])]
        // console.log(`deleting ids: ${idsToDelete}`)
        this.usedAnalysesIds = this.usedAnalysesIds.filter((id) => !idsToDelete.includes(id))
        this.updateSelectItems()
    }
}
